import { CityTranslation } from "./city-translation";

export class City {
    public id: string;
    // tslint:disable-next-line:naming-convention
    public created_at: string;
    // tslint:disable-next-line:naming-convention
    public updated_at: string;
    public translations: CityTranslation[];
    public constructor(city: City) {
        if (city) {
            $.extend(this, city);
        } else {
            this.translations = [];
        }
    }
}
