import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/internal/operators/map";
import { environment } from "src/environments/environment";
import { City } from "../models/city";
import { NewCity } from "../models/new-city";

@Injectable({
    providedIn: "root"
})
export class CitiesService {
    public allLanguages: Set<string> = new Set<string>();

    public constructor(
        private http: HttpClient,
    ) { }

    /**
     *
     * GET Cities
     *
     */

    public getCities(): Observable<City[]> {
        return this.http.get<City[]>(environment.backEnd_Url + "/cities").pipe(map((cities: City[]) =>
            cities.map((city: City) => new City(city))));
    }

    /**
     *
     * POST City
     *
     */

    public addCity(newCity: NewCity, translation: string): Observable<City> {
        // tslint:disable-next-line:typedef
        const options = { headers: this.getHeaders(translation) };
        return this.http.post<City>(environment.backEnd_Url + "/cities", newCity, options);
    }

    /*
     *
     * Collect all the cities languages
     *
     */
    public getLanguages(): Set<string> {
        this.getCities().subscribe((tours: City[]) => {
            tours.forEach((city: City) => {
                for (const translation of city.translations) {
                    this.allLanguages.add(translation.locale);
                }
            });
        });
        this.allLanguages.add("de");
        this.allLanguages.add("en");
        return this.allLanguages;
    }

    /**
     *
     * GET City/id
     *
     */

    public getCity(id: number): Observable<City> {
        return this.http.get<City>(environment.backEnd_Url + "/cities/" + id).pipe(map((city: City) => new City(city)));
    }

    /**
     *
     * PUT City/id
     *
     */

    public editCity(id: string, city: NewCity, translation: string): Observable<object> {
        // tslint:disable-next-line:typedef
        const options = { headers: this.getHeaders(translation) };
        return this.http.put(environment.backEnd_Url + "/cities/" + id, city, options);
    }

    /**
     *
     * DELETE City/id
     *
     */

    public deleteCity(id: string): Observable<object> {
        return this.http.delete(environment.backEnd_Url + "/cities/" + id);
    }

    /*
    *
    * Request header
    *
    */
    private getHeaders(language: string): HttpHeaders {
        return new HttpHeaders().set("Accept-Language", language);
    }
}
